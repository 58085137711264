<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="flex-col">
                    Sub-accounts
                    <div class="storage">
                        <span :style="`width: ${ (organizationLocations.length * 100) / membership.locations_limit }%;`"></span>
                        <h4>You are using {{ organizationLocations.length }} of {{ membership.locations_limit ? membership.locations_limit : 0 }} allowed sub-accounts.</h4>
                    </div>
                </h1>
                <button class="close_btn left_out" @click="closeModal()">
                    <i class="fas fa-long-arrow-alt-right"></i>
                </button>
            </div>
            <div class="modal_body">
                <div class="loader_area2" v-show="locationLoader"><quote-loader/></div>
                <div v-show="!locationLoader">
                    <div class="section_wpr">
                        <h3>Sub-account List <button type="button" class="add_btn m-0" @click="handleAddLocation()"><i class="fas fa-plus"></i>Add Sub-account</button></h3>
                    </div>
                    <div class="tempcard">
                        <ul class="mt-1" v-click-outside="closeLocationDropdown">
                            <li v-for="(location, l) of organizationLocations" :key="l" @click.self="toggleActiveLocation(0, {})">
                                <div class="tempcard_details">
                                    <h5>{{ location.company_name }}</h5>
                                    <p><img src="@/assets/images/client.svg">{{ location.first_name }} {{ location.last_name }}</p>
                                </div>
                                <span class="view" @click="toggleActiveLocation(location.id, location)">
                                    <div class="dropdown">
                                        <i class="fas fa-ellipsis-v"></i>
                                        <div class="drp_wrapper" :class="{ active: activeLocation == location.id }">
                                            <ul>
                                                <li @click="locationDetail = true">View Details</li>
                                                <li @click="handleSuspend(location)">{{ location.is_disable == 0 ? 'Suspend' : 'Activate' }}</li>
                                                <li @click="handleDelete(location)">Delete</li>
                                                <!-- <li @click="addUsers = true">Add User</li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="addLocation || locationDetail" class="details_wpr">
                    <div class="cardItem_details">
                        <Form @keydown.enter="$event.preventDefault()" @submit="handleSubmit" :validation-schema="schema" v-slot="{ errors }">
                            <div class="header">
                                <button class="close_btn" @click="addLocation = false; locationDetail = false;"><i class="fas fa-chevron-down"></i></button>
                                <h2 class="pb-2">{{ locationDetail ? 'Location Detail' : 'Add New Loaction' }}</h2>
                                <ul class="tab_sec mt-3 mb-0">
                                    <li @click="tab = 1" :class="{ active: tab === 1 }">Details</li>
                                    <li @click="tab = 2" :class="{ active: tab === 2 }">Settings</li>
                                    <li @click="tab = 3" :class="{ active: tab === 3 }">Advanced</li>
                                </ul>
                            </div>
                            <div class="tab_content mt-2">
                                <div class="setting_wpr" v-show="tab == 1">
                                    <div class="upload_image">
                                        <image-upload v-model="form.company_logo" image-type="public-logo" upload-text="Logo" :is-avatar="true" />
                                    </div>
                                    <div class="form_grp mb-1">
                                        <div class="group_item">
                                            <label class="input_label">Location Name</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.company_name }">
                                                <Field autocomplete="nofill" name="company_name" v-model="form.company_name" type="text" placeholder="ThriveCoach" />
                                            </div>
                                            <ErrorMessage name="company_name" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp mb-1">
                                        <div class="group_item">
                                            <label class="input_label">Location primary email</label>
                                            <div class="field_wpr has_prefix" :class="{ 'has-error': errors.email }">
                                                <Field autocomplete="nofill" name="email" v-model="form.email" type="text" placeholder="john.doe@onboardme.io" />
                                                <span class="prefix"><i class="fas fa-envelope"></i></span>
                                            </div>
                                            <ErrorMessage name="email" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp mb-1">
                                        <div class="group_item">
                                            <label class="input_label">Owner First Name</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.first_name }">
                                                <Field autocomplete="nofill" name="first_name" v-model="form.first_name" type="text" placeholder="Robert" />
                                            </div>
                                            <ErrorMessage name="first_name" class="text-danger" />
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">Owner Last Name</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.last_name }">
                                                <Field autocomplete="nofill" name="last_name" v-model="form.last_name" type="text" placeholder="Hank" />
                                            </div>
                                            <ErrorMessage name="last_name" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp mb-1">
                                        <div class="group_item">
                                            <label class="input_label">Address</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.address }">
                                                <Field autocomplete="nofill" name="address" v-model="form.address" type="text" placeholder="286 Whitehill Street" />
                                            </div>
                                            <ErrorMessage name="address" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp mb-1">
                                        <div class="group_item">
                                            <label class="input_label">City</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.city }">
                                                <Field autocomplete="nofill" name="city" v-model="form.city" type="text" placeholder="Lemont" />
                                            </div>
                                            <ErrorMessage name="city" class="text-danger" />
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">State/Province</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.state }">
                                                <Field autocomplete="nofill" name="state" v-model="form.state" type="text" placeholder="PA" />
                                            </div>
                                            <ErrorMessage name="state" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp mb-1">
                                        <div class="group_item">
                                            <label class="input_label">Zip/Postal Code</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.zipcode }">
                                                <Field autocomplete="nofill" name="zipcode" v-model="form.zipcode" type="text" placeholder="16851" />
                                            </div>
                                            <ErrorMessage name="zipcode" class="text-danger" />
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">Country</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.country }">
                                                <Field autocomplete="off" name="country" v-model="form.country" :class="{ 'has-error': errors.country }">
                                                    <multiselect
                                                        v-model="form.country"
                                                        :options="countries"
                                                        value-prop="code"
                                                        label="country"
                                                        :searchable="true"
                                                        placeholder="Select country"
                                                        autocomplete="nofill"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="country" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp mb-1">
                                        <div class="group_item">
                                            <label class="input_label">Mobile Phone Number</label>
                                            <div class="field_wpr">
                                                <Field name="mobile_no" autocomplete="nofill" type="text" v-model="form.mobile_no" placeholder="(814) 555-1212" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp mb-1">
                                        <div class="group_item">
                                            <label class="input_label">Business Phone Number</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" name="business_no" type="text" v-model="form.business_no" placeholder="(814) 555-1212" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Website</label>
                                            <div class="field_wpr has_prefix" :class="{ 'has-error': errors.website }">
                                                <Field autocomplete="nofill" name="website" v-model="form.website" type="text" placeholder="www.yoursite.com" />
                                                <span class="prefix">URL</span>
                                            </div>
                                            <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                            <ErrorMessage name="website" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <div class="setting_wpr" v-show="tab == 2">
                                    <label for="send-recieve-email" class="switch_option capsule_btn">
                                        <h5>Send/received emails</h5>
                                        <input type="checkbox" id="send-recieve-email" v-model="form.email_status" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <label for="send-recieve-sms" class="switch_option capsule_btn">
                                        <h5>Send/recieve SMS</h5>
                                        <input type="checkbox" id="send-recieve-sms" v-model="form.sms_status" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <label for="show-all-contacts" class="switch_option capsule_btn">
                                        <h5>User can see all contacts</h5>
                                        <input type="checkbox" id="show-all-contacts" v-model="form.allow_contacts" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div class="form_grp mb-1">
                                        <div class="group_item">
                                            <label class="input_label">Send SMS notifications to</label>
                                            <div class="field_wpr">
                                                <Field name="sms_notification_to" autocomplete="nofill" type="text" v-model="form.sms_notification_to" placeholder="(814) 555-1212" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp mb-1">
                                        <div class="group_item">
                                            <label class="input_label">REPLY TO Email</label>
                                            <div class="field_wpr has_prefix" :class="{ 'has-error': errors.reply_to_email }">
                                                <Field autocomplete="nofill" name="reply_to_email" type="email" v-model="form.reply_to_email" placeholder="john.doe@onboardme.io" />
                                                <span class="prefix"><i class="fas fa-envelope"></i></span>
                                            </div>
                                            <ErrorMessage name="reply_to_email" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp mb-1">
                                        <div class="group_item">
                                            <label class="input_label">Timezone of your location</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.timezone }">
                                                <Field autocomplete="off" name="timezone" v-model="form.timezone" :class="{ 'has-error': errors.timezone }">
                                                    <multiselect
                                                      v-model="form.timezone"
                                                      :options="timezones"
                                                      valueProp="code"
                                                      label="timezone"
                                                      :searchable="true"
                                                      autocomplete="nofill"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="timezone" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Location Currency</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.currency }">
                                                <Field autocomplete="off" name="currency" v-model="form.currency" :class="{ 'has-error': errors.currency }">
                                                    <multiselect
                                                      v-model="form.currency"
                                                      :options="currencies"
                                                      valueProp="code"
                                                      label="currency"
                                                      :searchable="true"
                                                      autocomplete="nofill"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="currency" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <div class="setting_wpr" v-show="tab == 3">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">API key</label>
                                            <div class="field_wpr">
                                                <input type="text" readonly v-model="form.access_token" v-if="viewAccessToken">
                                                <input type="text" readonly v-else>
                                                <span class="icon" @click="viewAccessToken = !viewAccessToken">
                                                    <i class="far fa-eye-slash" v-if="viewAccessToken"></i>
                                                    <i class="far fa-eye" v-else></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Email Domain</label>
                                            <div class="field_wpr">
                                                <input type="text" readonly v-model="form.mailgun_email" placeholder="ex:postmaster@onboardme.io">
                                            </div>
                                        </div>
                                    </div>
                                    <label for="email-subscription" class="switch_option capsule_btn border-0">
                                        <h5>Mail Subscription<span>Stay up to date with real time email notifications</span></h5>
                                        <input type="checkbox" id="email-subscription" v-model="form.mail_subscription" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div class="blank_sec">
                                        <img src="@/assets/images/Admin-Settings.svg">
                                    </div>
                                </div>
                            </div>
                            <div class="action_wpr mt-5">
                                <button :disabled="locationSaveLoader" type="button" class="btn cancel_btn" @click="addLocation = false; locationDetail = false;">Cancel</button>
                                <button :disabled="locationSaveLoader" class="btn save_btn">
                                    <template v-if="locationDetail">
                                        <i class="fa fa-spin fa-spinner" v-if="locationSaveLoader"></i> {{ locationSaveLoader ? 'Updating' : 'Update' }}
                                    </template>
                                    <template v-else>
                                        <i class="fa fa-spin fa-spinner" v-if="locationSaveLoader"></i> {{ locationSaveLoader ? 'Adding' : 'Add' }}
                                    </template>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
                <div v-if="addUsers" class="details_wpr">
                    <Form @keydown.enter="$event.preventDefault()" @submit="handleAddContact" v-slot="{ errors }" class="w-100">
                        <div class="cardItem_details">
                            <div class="header">
                                <button class="close_btn" @click="addUsers = false"><i class="fas fa-chevron-down"></i></button>
                                <h2>Add Users</h2>
                            </div>
                            <div class="setting_wpr mt-2">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Which users can access your location</label>
                                        <Field autocomplete="off" type="hidden" name="location_id" v-model="selectedLocation.id" />
                                        <div class="field_wpr" :class="{ 'has-error': errors.location_user }">
                                            <Field autocomplete="off" name="location_user" v-model="locationUsers" :class="{ 'has-error': errors.location_user }" rules="required" label="location user">
                                                <multiselect
                                                    v-model="locationUsers"
                                                    :options="organizationUsers"
                                                    value-prop="id"
                                                    label="full_name"
                                                    :searchable="true"
                                                    placeholder="Select users"
                                                    mode="tags"
                                                ></multiselect>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="location_user" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="action_wpr mt-5">
                                <button :disabled="locationSaveLoader" class="btn cancel_btn" type="button" @click="addUsers = false">Cancel</button>
                                <button :disabled="locationSaveLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="locationSaveLoader"></i> {{ locationSaveLoader ? 'Adding' : 'Add' }}</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="locationSaveLoader" class="btn cancel_btn" type="button">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))

    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'
    import * as yup from 'yup'

    export default {
        name: 'Primary Location',

        data () {
            const schema = yup.object({
                first_name: yup.string().required().label('The first name'),
                last_name: yup.string().required().label('The last name'),
                email: yup.string().required().email().label('The email'),
                company_name: yup.string().required().label('The location name'),
                reply_to_email: yup.string().email().label('The reply to email'),
                website: yup.string().url().label('The website'),
            });

            return {
                tab: 1,
                form: {

                },
                schema,
                locationDetail: false,
                addUsers: false,
                addLocation: false,
                activeLocation: 0,
                viewAccessToken: false,
                selectedLocation: {},
                locationUsers: [],
                billingApi: process.env.VUE_APP_BILLING_API_URL,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ImageUpload,
        },

        watch: {
            'form.country' (country) {
                const vm = this;

                vm.getTimezonesByCountry({ country });
            },

            addLocation () {
                const vm = this;

                vm.resetForm();
            },

            addUsers (val) {
                const vm = this;

                vm.locationUsers = [];

                if (val && vm.selectedLocation && vm.selectedLocation.location_users) {
                    vm.locationUsers = vm.selectedLocation.location_users.map(({ id }) => id);
                }
            },

            selectedLocation (location) {
                const vm = this;

                vm.form = {
                    id: location.id ? location.id : 0,
                    company_logo: location.company_logo ? location.company_logo : '',
                    company_name: location.company_name ? location.company_name : '',
                    first_name: location.first_name ? location.first_name : '',
                    last_name: location.last_name ? location.last_name : '',
                    allow_contacts: location.allow_contacts ? location.allow_contacts : 0,
                    mobile_no: location.mobile_no ? location.mobile_no : '',
                    business_no: location.business_no ? location.business_no : '',
                    email: location.email ? location.email : '',
                    email_status: location.email_status ? location.email_status : '',
                    mail_subscription: location.mail_subscription ? location.mail_subscription : '',
                    sms_status: location.sms_status ? location.sms_status : '',
                    reply_to_email: location.reply_to_email ? location.reply_to_email : '',
                    sms_notification_to: location.sms_notification_to ? location.sms_notification_to : '',
                    address: location.address ? location.address : '',
                    city: location.city ? location.city : '',
                    state: location.state ? location.state : '',
                    country: location.country ? location.country : '',
                    zip_code: location.zip_code ? location.zip_code : '',
                    timezone: location.timezone ? location.timezone : '',
                    currency: location.currency ? location.currency : '',
                    website: location.website ? location.website : '',
                    mailgun_email: location.user && location.user.mailgun_email ? location.user.mailgun_email : '',
                    access_token: location.user && location.user.access_token ? location.user.access_token : '',
                };
            },

            modelValue (value) {
                if (value) {
                    const vm = this;
                    document.body.classList.add('modal-open');

                    vm.addLocation = false;
                    vm.locationDetail = false;

                    vm.form = {};
                    vm.resetForm();

                    setTimeout(function () {
                        if (vm.countries.length == 0) {
                            vm.getCountries();
                        }

                        if (vm.organizationUsers.length == 0) {
                            vm.getOrganizationUsers();
                        }

                        if (vm.organizationLocations.length == 0) {
                            vm.getOrganizationLocations();
                        }
                    }, 1000);
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            countries: state => state.commonModule.countries,
            timezones: state => state.commonModule.timezonesByCountry,
            currencies: state => state.commonModule.currencies,
            organizationUsers: state => state.authModule.organizationUsers,
            organizationLocations: state => state.authModule.organizationLocations,
            locationLoader: state => state.authModule.locationLoader,
            locationSaveLoader: state => state.authModule.locationSaveLoader,
            membership: state => state.authModule.membership,
            plansAndAddons: state => state.billingModule.plans,
            subscription: state => state.billingModule.subscription,
        }),

        methods: {
            ...mapActions({
                refreshAuth: 'authModule/refreshAuth',
                getCountries: 'commonModule/getCountries',
                getTimezonesByCountry: 'commonModule/getTimezonesByCountry',
                getOrganizationUsers: 'authModule/getOrganizationUsers',
                getOrganizationLocations: 'authModule/getOrganizationLocations',
                saveOrganizationLocation: 'authModule/saveOrganizationLocation',
                deleteLocation: 'authModule/deleteLocation',
                suspendLocation: 'authModule/suspendLocation',
                addUserToLocation: 'authModule/addUserToLocation',
            }),

            closeModal () {
                const vm = this;

                vm.tab = 1;
                vm.$emit('update:modelValue', false);
            },

            toggleActiveLocation (id = 0, location = {}) {
                const vm = this;

                vm.activeLocation = id == vm.activeLocation ? 0 : id;
                vm.selectedLocation = location;
            },

            handleSubmit (params, { setFieldError }) {
                const vm = this;
                const form = vm.form;

                form.setFieldError  = setFieldError;

                vm.saveOrganizationLocation(form).then((result) => {
                    if (result) {
                        vm.refreshAuth();
                        vm.getOrganizationLocations();
                        vm.tab = 1;
                        vm.locationDetail = false;
                        vm.addLocation = false;
                    }
                });
            },

            handleDelete (location) {
                const vm = this;
                let html  = `<center>This action is permanent. You will not be able to recover this location</center>
                            <label for="delete-contacts" class="switch_option capsule_btn">
                                <h5>Delete Contacts</h5>
                                <input type="checkbox" id="delete-contacts" value="1" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="delete-assets" class="switch_option capsule_btn">
                                <h5>Delete Assets</h5>
                                <input type="checkbox" id="delete-assets" value="1" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="delete-client-portal" class="switch_option capsule_btn">
                                <h5>Delete Client Portal</h5>
                                <input type="checkbox" id="delete-client-portal" value="1" hidden>
                                <div><span></span></div>
                            </label>`;

                const options = Helper.swalConfirmOptions('Are you sure?', html, 'Delete');

                options.preConfirm = () => {
                                          const params = {
                                              delete_contacts: document.querySelector('#delete-contacts').checked,
                                              delete_assets: document.querySelector('#delete-assets').checked,
                                              delete_client_portal: document.querySelector('#delete-client-portal').checked,
                                              location_id: location.id,
                                          };

                                          return vm.deleteLocation(params).then((result) => {
                                              if (result) {
                                                  vm.refreshAuth();
                                                  vm.getOrganizationLocations();
                                              }
                                          })
                                      };

                Swal.fire(options);
            },

            handleSuspend (location) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `You want to ${location.is_disable == 0 ? 'suspend' : 'activate'} this location`, `${location.is_disable == 0 ? 'Suspend' : 'Activate'}`);

                options.preConfirm = () => {
                                          return vm.suspendLocation({ location_id: location.id, status: location.is_disable ? 0 : 1 }).then((result) => {
                                              if (result) {
                                                  vm.getOrganizationLocations();
                                              }
                                          })
                                      };

                Swal.fire(options);
            },

            handleAddContact (params) {
                const vm = this;

                vm.addUserToLocation(params).then((result) => {
                    if (result) {
                        vm.addUsers = false;
                        vm.locationUsers = [];
                        vm.getOrganizationLocations();
                    }
                });
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    company_logo: '',
                    company_name: '',
                    first_name: '',
                    last_name: '',
                    allow_contacts:  0,
                    mobile_no: '',
                    business_no: '',
                    email: '',
                    email_status: '',
                    mail_subscription: '',
                    sms_status: '',
                    reply_to_email: '',
                    sms_notification_to: '',
                    address: '',
                    city: '',
                    state: '',
                    country: vm.user.country,
                    zip_code: '',
                    timezone: '',
                    currency: '',
                    website: '',
                    mailgun_email: '',
                    access_token: '',
                };
            },

            closeLocationDropdown () {
                const vm = this;

                vm.activeLocation = 0;
            },

            handleAddLocation () {
                const vm = this;

                if (!vm.membership.locations) {
                    vm.addLocation = true;
                } else {
                    if (vm.user.stripe_id) {
                        const options = Helper.swalConfirmOptions('Are you sure?', `You have exceeded your limit. If you want to add more location, you have to add another additional location plan.`, 'PROCEED', false);

                        Swal.fire(options).then((result) => {
                            if (result.isConfirmed ) {
                                vm.billingConfirmation();
                            }
                        });
                    } else {
                        const options = Helper.swalConfirmOptions('Limit exceeded', 'You have reached your locations limit for this plan. Please contact support or open a chat to upgrade your account and unlock more features.', 'Contact Support', false);

                        Swal.fire(options).then((result) => {
                            if (result.isConfirmed) {
                                window.location = 'mailto:support@onboardme.io?subject=Upgrade Plan';
                            }
                        });
                    }
                }
            },

            billingConfirmation () {
                const vm = this;
                const addonPlan = vm.plansAndAddons.addons.month.filter((plan) => plan.nickname == 'location')[0];
                const options = Helper.swalConfirmOptions('Thank You!', `$${Math.round((parseFloat(addonPlan.amount) + Number.EPSILON) * 100) / 100} will be charged now and will be added to your next billing. Would you like to proceed?`, 'PROCEED');
                options.preConfirm = function () {
                                          return new Promise(function(resolve, reject) {
                                              axios.post(`${vm.billingApi}/stripe/subscription/increment-quantity`, { plan: addonPlan.price_id }, {
                                                  headers: {
                                                      Accept: 'application/json',
                                                      Authorization: `Bearer ${vm.user.access_token}`,
                                                  },
                                              }).then((resp) => {
                                                  vm.refreshAuth();

                                                  Toastr.success('One additional location add-on has been added to your subscription successfully!');
                                                  resolve(true);
                                              }).catch((err) => {
                                                  Toastr.error(err.response.data.error);
                                                  resolve(true);
                                              });
                                          });
                                      }

                Swal.fire(options);
            },
        },
    }
</script>

<style scoped>
    .dropdown .drp_wrapper {
        display: none;
    }
    .dropdown .drp_wrapper.active {
        display: block;
    }
    :deep(.upload_image){
        margin-bottom: 0;
    }
    :deep(.upload_image .tab_content){
        height: auto;
    }
    .storage {
        padding: 5px 15px;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 3px;
        position: relative;
        overflow: hidden;
        margin: 10px 0;
    }

    .storage h4 {
        font-size: 10px;
        line-height: 12px;
        color: #5a5a5a;
        font-weight: 500;
        position: relative;
        z-index: 1;
    }

    .storage span {
        background: rgba(47, 126, 237, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    @media(max-width: 767px){
        .storage{
            margin-bottom: 0;
        }
    }
</style>
